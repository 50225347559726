import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import UserList from '../Users/UserList';
import * as urls from '../../constants/urls';
import Login from '../Login/Login';
import BuyerDetails from '../Users/BuyerDetails';
import SellerDetails from '../Users/SellerDetails';
import StoreList from '../Stores/StoreList';
import StoreDetails from '../Stores/StoreDetails';
import Settings from '../Settings/Settings';
import RouteLayout from './RouteLayout';
import ProtectedRoute from './ProtectedRoute';
import {Roles} from '../../utils/roles';
import NotFound from '../NotFound/404';
import ProductDetails from '../Stores/Details/ProductCategoriesTab/ProductDetails';
import DiscountDetails from '../Stores/Details/Discount/DiscountDetails';
import TransactionList from '../Transactions/TransactionList';
import OrderList from '../Orders/OrderList';
import OrderDetails from '../Orders/OrderDetails';
import ErrorPage from '../Error/Error';
import * as Sentry from '@sentry/react';
import AppList from '../Apps/AppList';
import AppDetails from '../Apps/Details/AppDetails';

const fallback = <ErrorPage />;
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const AppRoutes = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<RouteLayout />}
      hasErrorBoundary
      errorElement={fallback}
    >
      {/* Public routes */}
      {/* Login */}
      <Route path={urls.LOGIN_PATH} element={<Login />} />

      {/* Protected Admin Routes with roles */}
      <Route element={<ProtectedRoute allowedRoles={[Roles.Admin]} />}>
        {/* Users List */}
        <Route
          path={`${urls.USERS_LIST_PATH}/(seller|buyer|admin|guest)?`}
          element={<UserList />}
        />
        <Route path={urls.USERS_LIST_TAB_PATH} element={<UserList />} />
        {/* Buyer details */}
        <Route
          path={`${urls.BUYER_DETAILS_PATH}/:type?`}
          element={<BuyerDetails />}
        />
        {/* Seller details */}
        <Route path={urls.SELLER_DETAILS_PATH} element={<SellerDetails />} />
        {/* Settings */}
        <Route path={urls.SETTINGS_PATH} element={<Settings />} />
        {/* Transactions */}
        <Route
          path={urls.TRANSACTION_LIST_PATH}
          element={<TransactionList />}
        />
        {/* Transactions */}
        <Route path={urls.APP_LIST_PATH} element={<AppList />} />
        <Route
          path={`${urls.APP_DETAILS_PATH}/:tab?`}
          element={<AppDetails />}
        />
      </Route>

      {/* Protected Seller Routes with roles */}
      <Route element={<ProtectedRoute allowedRoles={[Roles.Seller]} />}></Route>

      {/* Protected Admin and Seller Routes with roles */}
      <Route
        element={<ProtectedRoute allowedRoles={[Roles.Seller, Roles.Admin]} />}
      >
        {/* Order Lists */}
        <Route
          path={`${urls.ORDERS_LIST_PATH}/(pending|in_progress|cancelled|completed)?`}
          element={<OrderList />}
        />
        <Route path={urls.ORDER_DETAILS_PATH} element={<OrderDetails />} />
        {/* Stores list */}
        <Route path={urls.STORES_LIST_PATH} element={<StoreList />} />
        {/* Store details */}
        <Route
          path={`${urls.STORE_DETAILS_PATH}/:tab?`}
          element={<StoreDetails />}
        />

        {/* Product details */}
        <Route path={urls.PRODUCT_DETAILS_PATH} element={<ProductDetails />} />

        {/* Discount details */}
        <Route
          path={urls.DISCOUNT_DETAILS_PATH}
          element={<DiscountDetails />}
        />
      </Route>
      {/* catch all, redirect unknown routes to login */}
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

export default AppRoutes;
