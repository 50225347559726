import {useIntl} from 'react-intl';
import {useEffect, useState} from 'react';
import {combineDateTime, formatErrorMessage} from '../utils/functions';
import {
  useNavigate,
  unstable_usePrompt as usePrompt,
  useParams,
} from 'react-router-dom';
import {
  Discount,
  DiscountAmountType,
  DiscountStatus,
  DiscountType,
} from '../types/Discount';
import {
  DiscountRequestParameter,
  useCreateDiscountMutation,
  useDeleteDiscountMutation,
  useGetDiscountQuery,
  useUpdateDiscountMutation,
} from '../services/discountApi';
import moment from 'moment';
import useDiscountFormRequest, {
  DiscountEligibility,
  DiscountEligibilityCondition,
  DiscountForm,
  UseDiscountCode,
} from './discount/useDiscountFormRequest';
import {OptionProps} from '../components/Form/Inputs/AutocompleteMultiple';
import * as urls from '../constants/urls';

const dateFormat = 'YYYY-MM-DD';
export default function useDiscount() {
  // translations
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const [discountId, setDiscountId] = useState<undefined | number>();
  const [storeId, setStoreId] = useState<undefined | number>();
  const [count, setCount] = useState(0);
  const [discount, setDiscount] = useState<undefined | Discount>();
  const [defaultRepeatedDays, setDefaultRepeatedDays] = useState<OptionProps[]>(
    []
  );

  // create discount api
  const [createDiscount, {isSuccess: createSuccess}] =
    useCreateDiscountMutation();

  // delete discount api
  const [deleteDiscount, {isSuccess: deleteSuccess}] =
    useDeleteDiscountMutation();

  // update discount api
  const [updateDiscount, {isSuccess: updateSuccess}] =
    useUpdateDiscountMutation();

  // alert when user try to leave page when edited field has not been saved
  usePrompt({
    when: count > 0 && !createSuccess,
    message: intl.formatMessage({id: 'dashboard.confirm_not_saved_message'}),
  });
  // alert when user try to reload when edited field has not been saved
  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent) => {
      if (count > 0 && !createSuccess) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [count]);

  // set storeId and discountId
  // set discountId to undefined for new discount
  useEffect(() => {
    if (params.storeId && params.discountId) {
      setStoreId(Number(params.storeId) ?? undefined);
      setDiscountId(
        params.discountId === 'new' ? undefined : Number(params.discountId)
      );
    }
  }, [params]);

  // fetch discount
  const {data, isSuccess} = useGetDiscountQuery(
    {
      id: discountId,
      showProgressDialog: true,
    },
    {
      skip: !discountId,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  // discount form request hook
  const {
    watch,
    handleSubmit,
    control,
    reset,
    setError,
    setValue,
    resetField,
    formState: {dirtyFields},
  } = useDiscountFormRequest();
  // form watch
  const formValuesWatch = watch();

  const countDirtyFields = (data: any) => {
    let fieldsCount = 0;
    if (Array.isArray(data)) {
      for (const item of data) {
        fieldsCount += countDirtyFields(item);
      }
    } else if (typeof data === 'object' && data !== null) {
      for (const key in data) {
        if (
          key === 'itemsValues' ||
          key === 'value' ||
          (key === 'secondValue' && Array.isArray(data['items']))
        ) {
          continue;
        }
        if (
          key === 'items' &&
          Array.isArray(data[key]) &&
          data[key].includes(true)
        ) {
          fieldsCount++;
        } else if (data[key] === true) {
          fieldsCount++;
        } else {
          fieldsCount += countDirtyFields(data[key]);
        }
      }
    }
    return fieldsCount;
  };

  // Count changed fields
  useEffect(() => {
    if (formValuesWatch) {
      setCount(countDirtyFields(dirtyFields));
    }
  }, [formValuesWatch]);

  const discountTypeWatch = watch('type');
  // hide/show the repeat days field
  useEffect(() => {
    if (discountTypeWatch && discountTypeWatch === DiscountType.LoyaltyOffer) {
      setValue('repeatable.repeat', false);
    } else if (
      discountTypeWatch &&
      discountTypeWatch === DiscountType.OrderAmount
    ) {
      setValue(
        'repeatable.repeat',
        data?.discount?.validity?.repeatable ?? false
      );
    }
  }, [discountTypeWatch]);

  // reset form field
  const resetFormField = () => {
    resetField('eligibilities');
    reset();
  };

  // retrieve time from datetime stamp
  const retrieveTimeStampFromDate = (date: Date) => {
    return moment.tz(date, 'GMT').format('hh:mm A');
  };

  // retrieve date from datetime stamp
  const retrieveDate = (date: Date) => {
    return moment.tz(date, 'GMT').format(dateFormat);
  };

  // format Weekdays using the day number
  const retrieveWeekDay = (day: number) => ({
    title: `Every ${moment(day, 'e').format('dddd')}`,
    value: day,
  });

  // remove empty condition from Eligibility item
  const formatCondition = (eligibility: DiscountEligibilityCondition[]) => {
    const filteredEligibility = eligibility.filter(value => {
      if (Array.isArray(value.items)) {
        return value.items.length > 0;
      }
      return Boolean(value.items);
    });
    return filteredEligibility.map(value => ({
      ...value,
      items: (value.secondValue
        ? [value.items, value.secondValue]
        : value.items) as string | number | number[] | string[],
    }));
  };

  // remove empty eligibilities from the list
  const formatEligibilities = (
    eligibilities: DiscountEligibility[]
  ): DiscountEligibility[] => {
    // remove empty conditions from eligibility
    let filteredEligibilities = eligibilities.map(eligibility => ({
      ...eligibility,
      eligibilityConditions: formatCondition(eligibility.eligibilityConditions),
    }));
    // remove empty eligibilities that have no condition
    filteredEligibilities = filteredEligibilities.filter(
      eligibility => eligibility.eligibilityConditions.length > 0
    );
    return filteredEligibilities;
  };

  // set default values on edit
  const handleSetValue = (discount: Discount) => {
    reset({
      isActive: discount.isActive
        ? DiscountStatus.Active
        : DiscountStatus.Inactive,
      amount:
        discount.amountType === DiscountAmountType.Fixed
          ? discount.amount
          : discount.amountPercent,
      name: discount.name,
      type: discount.type,
      totalMinOrderAmount: discount.validity.totalMinOrderAmount,
      totalMinOrderTimesReached: discount.validity
        ?.totalMinOrderTimesReached as number,
      amountType: discount.amountType,
      checkoutMessage: discount.checkoutMessage,
      useDiscountCode: discount?.code
        ? UseDiscountCode.Yes
        : UseDiscountCode.No,
      code: discount?.code ?? '',
      description: discount?.description,
      deliveryType: discount.deliveryType,
      usageLimit: discount.usage?.usageLimit as number,
      limitDiscountUsage: !!discount.usage?.usageLimit,
      singleUse: discount.usage.singleUse,
      blockPromotion: discount.usage.blockPromotion,
      startDate: retrieveDate(discount.validity.validityStartDate),
      startTime: retrieveTimeStampFromDate(discount.validity.validityStartDate),
      endDate: retrieveDate(discount.validity?.validityEndDate ?? new Date()),
      endTime: retrieveTimeStampFromDate(
        discount.validity?.validityEndDate ?? new Date()
      ),
      repeatable: {
        repeat: discount.validity.repeatable,
        setEndDate: !!discount.validity?.validityEndDate,
      },
      repeatableDays: discount.validity?.repeatableDays
        ? discount.validity?.repeatableDays.map(day => retrieveWeekDay(day))
        : undefined,
      eligibilities: discount.eligibilities,
    });
    setDefaultRepeatedDays(
      discount.validity?.repeatableDays
        ? discount.validity?.repeatableDays.map(day => retrieveWeekDay(day))
        : []
    );
  };

  // prefill discount data on edit
  useEffect(() => {
    if (isSuccess && data) {
      setDiscount(data.discount);
      handleSetValue(data.discount);
    }
  }, [isSuccess, data]);

  // format invalid date
  // combine date and time to datetime
  const formatDate = (
    date: string,
    time: string,
    iFormat?: string,
    fFormat?: string,
    defaultValue = null
  ) => {
    const m = moment(date, iFormat);
    const timeFormat = moment(time, 'hh:mm A');

    const validDate = m.isValid() ? m.format(fFormat) : defaultValue;
    if (validDate) {
      return combineDateTime(validDate, timeFormat.format('HH:mm:ss'));
    }
    return null;
  };

  // submit discount request
  const submit = (formValues: DiscountForm) => {
    const data: DiscountRequestParameter = {
      id: discountId,
      body: {
        ...formValues,
        storeId,
        amount: Number(formValues.amount),
        isActive: formValues.isActive === DiscountStatus.Active,
        code:
          formValues.useDiscountCode === UseDiscountCode.Yes
            ? formValues.code
            : null,
        usage: {
          id: discount?.usage?.id,
          blockPromotion: formValues.blockPromotion,
          singleUse: formValues.singleUse,
          usageLimit: formValues.limitDiscountUsage
            ? Number(formValues.usageLimit)
            : null,
        },
        validity: {
          id: discount?.validity?.id,
          repeatable: formValues.repeatable.repeat,
          totalMinOrderAmount: Number(formValues.totalMinOrderAmount),
          totalMinOrderTimesReached:
            formValues.type === DiscountType.LoyaltyOffer
              ? Number(formValues.totalMinOrderTimesReached)
              : null,
          validityStartDate: formatDate(
            formValues.startDate as string,
            formValues.startTime ?? '00:00:00',
            dateFormat
          ) as Date,
          validityEndDate: formValues.repeatable.setEndDate
            ? (formatDate(
                formValues.endDate as string,
                formValues.endTime ?? '00:00:00',
                dateFormat
              ) as Date)
            : null,
          repeatableDays: formValues.repeatable.repeat
            ? formValues.repeatableDays.map(item => item.value as number)
            : null,
        },
        eligibilities: formatEligibilities(formValues.eligibilities),
        status: discount?.status ?? DiscountStatus.Active,
      },
      showProgressDialog: true,
      formatErrorMessage: error => {
        if (error.data.message === 'EXISTING_RESOURCE_DISCOUNT') {
          setError('code', {
            type: 'custom',
            message: intl.formatMessage({
              id: `error.${error?.data?.message}`,
            }),
          });
          return '';
        } else {
          return formatErrorMessage(error, intl);
        }
      },
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: discount
              ? 'messages.update_discount'
              : 'messages.create_discount',
          },
          {name: discount?.name}
        );
      },
    };

    if (discount) {
      updateDiscount(data);
    } else {
      createDiscount(data);
    }
  };

  // go back to discount lists after saving/deleting
  useEffect(() => {
    if (createSuccess || deleteSuccess) {
      setCount(0);
      if (!storeId) {
        return;
      }
      navigate(
        urls.STORE_DETAILS_TAB.replace(':storeId', storeId.toString()).replace(
          ':tab',
          'discount'
        )
      );
    }
    if (updateSuccess) {
      setCount(0);
    }
  }, [createSuccess, deleteSuccess, updateSuccess]);

  // delete discount request
  const deleteDiscountRequest = () => {
    deleteDiscount({
      id: discountId,
      showProgressDialog: true,
      formatErrorMessage: error => formatErrorMessage(error, intl),
      formatSuccessMessage: () => {
        return intl.formatMessage(
          {
            id: 'messages.delete_discount',
          },
          {name: discount?.name}
        );
      },
    });
  };

  return {
    control,
    handleSubmit,
    watch,
    submit,
    discountId,
    createSuccess,
    resetFormField,
    count,
    deleteDiscountRequest,
    discount,
    updateSuccess,
    formValuesWatch,
    defaultRepeatedDays,
    setValue,
    dirtyFields,
  };
}
